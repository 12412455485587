<template>
  <div></div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
p {
  @apply mb-2;
}
</style>
