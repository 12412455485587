import Vue from 'vue'
import Vuex from 'vuex'

import {access} from '@/plugins/store/access'
import {auth} from '@/plugins/store/auth'
import {error} from '@/plugins/store/error'
import {feedback} from '@/plugins/store/feedback'
import {interaction} from '@/plugins/store/interaction'
import {bookmark} from '@/plugins/store/bookmark'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        access,
        auth,
        error,
        feedback,
        interaction,
        bookmark
    },
    strict: debug
})
