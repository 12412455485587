<template>
  <div class="flex flex-col md:flex-row">
    <div class='flex-1 p-8'>
      <p>Orchard is a tool to help you study.
        Choose which text sections you’d like to practice, and Orchard will
        suggest questions to work on.
        A dashboard will show you which sections and which types
        of questions you’ve spent the most time on.
        You can set goals for yourself, and track your progress.</p>
      <p>Orchard differs from WeBWorK in that it will not evaluate your work.
        It will show you pre-written solutions, which you can use to
        evaluate yourself.
        If you tell Orchard how you did on each problem, it can give you
        problems multiple times for review.
        As you practice, things that used to be hard should become easier.</p>
      <p>Orchard for your personal use, not for a grade.
        Instructors can see aggregate data from the class, but your individual
        information is kept private.</p>
      <div class='mb-4 text-center'>
        <router-link :to='{name:"signedInHome"}' tag='button'
                     class='btnPrimary'>
          Sign in
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  components: {},
  computed: {
    isSignedIn() {
      return this.$store.getters['auth/isSignedIn']
    }
  },
  mounted() {
    if (this.isSignedIn)
      this.$router.replace({name: 'signedInHome'})
  }
}
</script>

<style scoped>
p {
  @apply mb-4;
}
</style>
