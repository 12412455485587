import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/home/Home.vue'
import store from "@/plugins/store";
import AdminRoutes from '@/plugins/router/admin'
import StudentRoutes from '@/plugins/router/student'

Vue.use(Router)

/* Each route entry can have optional metadata configuration, see root page for
 * example.
 * Available meta config options:
 * - isPublic: if set to true, this page can be viewed without signing in.
 *    Defaults to false.
 * - layout: use a different layout (from layouts/ directory) to render the page.
 *    Defaults to DefaultLayout. Currently, no alternative layout other than
 *    default exists. Keeping the system around for a bit to see if we need it.
 * */

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {isPublic: true}, // allow non-signed in users access
            component: Home
        },
        {
            path: '/home',
            name: 'signedInHome',
            component: () => import('../views/home/SignedInHome.vue')
        },
        {
            path: '/signed-out',
            name: 'signedOut',
            meta: {isPublic: true},
            component: () => import('../views/SignedOut.vue')
        },
        {
            path: '/courses/:courseId',
            meta: {breadcrumb: 'Course'},
            component: () => import('../views/courses/CourseHome.vue'),
            props(route) {
                return {courseId: Number(route.params.courseId)}
            },
            children: StudentRoutes.concat([
                {
                    path: '/courses/:courseId/users',
                    name: 'enrolment',
                    meta: {
                        breadcrumb: 'Enrolment',
                        requiresInstructor: true,
                    },
                    component: () => import('../views/enrolment/EnrolmentTable.vue'),
                    props(route) {
                        return {courseId: Number(route.params.courseId)}
                    }
                },
                {
                    path: '/courses/:courseId/analysis',
                    name: 'analysis',
                    meta: {
                        breadcrumb: 'Analysis',
                        requiresInstructor: true,
                    },
                    component: () => import('../views/course_analysis/CourseAnalysis.vue'),
                    props(route) {
                        return {
                            courseId: Number(route.params.courseId),
                            activeTab: 'analysis'
                        }
                    }
                }
            ])
        },
        AdminRoutes,
    ],
})

router.beforeResolve((to, from, next) => {
    if (to.matched.every(record => record.meta.isPublic)) {
        next()
    } else {
        // Don't need to handle isSignedIn here because App.vue already does.
        var isAdmin = store.getters["access/isAdmin"]
        var isInstructor = store.getters["access/isInstructor"]

        if (to.matched.some(record => record.meta.requiresAdmin)){
            if (isAdmin) {
                next()
            } else {
                next({name: 'home'})
            }
        } else if (to.matched.some(record => record.meta.requiresInstructor)){
            if (isAdmin || isInstructor) {
                next()
            } else {
                next({name: 'home'})
            }
        } else {
            next()
        }
    }
})

export default router