import {ModelBase, CollectionBase} from '@/models/ModelBases'
import {integer, required} from 'vue-mc/validation'

import {toDateString} from '@/models/Helpers'


export class Bookmark extends ModelBase {
    defaults() {
        return {
            id: null,
            user_id: null,
            question_id: null,
            created: '',
            modified: ''
        }
    }

    mutations() {
        return {
            created: (created) => toDateString(created),
            modified: (modified) => toDateString(modified)
        }
    }

    validation() {
        return {
            user_id: integer.and(required),
            question_id: integer.and(required),
        }
    }

    routes() {
        return {
            save: '/questions/{question_id}/bookmarks',
            delete: '/questions/{question_id}/bookmarks',
        }
    }
}


export class BookmarkList extends CollectionBase {
    defaults() {
        return {
            course_id: null
        }
    }

    model() {
        return Bookmark
    }

    routes() {
        return {
            fetch: '/courses/{course_id}/bookmarks',
        }
    }
}
