export default [
  {
    path: '',
    name: 'courseHome',
    component: () => import('@/views/student/Question.vue'),
    props(route) {
      return {courseId: Number(route.params.courseId)}
    }
  },
  {
    path: '/courses/:courseId/questionId/:questionId',
    name: 'specificQuestion',
    component: () => import('@/views/student/Question.vue'),
    props(route) {
      return {
        courseId: Number(route.params.courseId),
        initQuestionId: Number(route.params.questionId)
      }
    }
  },
  {
    path: '/courses/:courseId/intro',
    meta: { breadcrumb: 'Intro' },
    component: () => import('../../views/student/intro/Intro.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'intro',
        meta: { breadcrumb: 'Handshake' },
        component: () => import('../../views/student/intro/Handshake.vue'),
        props(route) { return {
          courseId: Number(route.params.courseId),
          activeTab: "handshake"
        }}
      },
      {
        path: '/courses/:courseId/intro/goal',
        name: 'introGoal',
        meta: { breadcrumb: 'Goals' },
        component: () => import('../../views/student/Goal.vue'),
        props(route) { return {
          courseId: Number(route.params.courseId),
          activeTab: "goal"
        }}
      }
    ]
  },
  {
    path: '/courses/:courseId/bookmark',
    name: 'bookmark',
    meta: { breadcrumb: 'Bookmarks' },
    component: () => import('../../views/student/Bookmark.vue'),
    props(route) {
      return {
        courseId: Number(route.params.courseId),
        activeTab: "bookmark"
      }
    }
  },
  {
    path: '/courses/:courseId/subjects',
    name: 'subjects',
    meta: { breadcrumb: 'Subjects' },
    component: () => import('../../views/student/Subjects.vue'),
    props(route) {
      return {
        courseId: Number(route.params.courseId),
        activeTab: "subjects"
      }
    }
  }
]
