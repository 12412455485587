export const feedback = {
  namespaced: true,
  state: {
    numHintUsed: 0,
    numRevealUsed: 0,
    numSkipUsed: 0
  },
  getters: {
  },
  mutations: {
    addHint(state) {
      state.numHintUsed += 1
    },
    resetHint(state) {
      state.numHintUsed = 0
    },
    addReveal(state) {
      state.numRevealUsed += 1
    },
    resetReveal(state) {
      state.numRevealUsed = 0
    },
    addSkip(state) {
      state.numSkipUsed += 1
    },
    resetSkip(state) {
      state.numSkipUsed = 0
    },
  },
  actions: {
  }
}
