import Vue from 'vue'
import {Request, Model, Collection} from 'vue-mc'

/* Make vue-mc classes use our configured global axios. This is necessary
 * because the stock Collections class wasn't picking up the baseURL and the
 * auth token that we configured in axios for some reason.
 *
 * Solution from: https://github.com/FiguredLimited/vue-mc/issues/23#issuecomment-343290486
 */

export class RequestBase extends Request {
  send() {
    return Vue.prototype.axios
      .request(this.config)
      .then(this.createResponse)
      .catch((error) => {
        throw this.createError(error);
      });
  }
}

export class ModelBase extends Model {
  createRequest(config) {
    return new RequestBase(config);
  }
}

export class CollectionBase extends Collection {
  createRequest(config) {
    return new RequestBase(config);
  }
}
