import {ModelBase} from '@/models/ModelBases'
import {toDateString} from '@/models/Helpers'


export class Answer extends ModelBase {
  defaults() {
    return {
      id: null,
      course_id: null,
      question_id: null,
      user_id: null,
      response: '',
      difficulty: null,
      confidence: 0,
      created: '',
      modified: ''
    }
  }

  mutations() {
    return {
      // Difficulty needs to be an int or the API will treat it as a key to the
      // DifficultyLevel enum causing json serialization issues
      difficulty: (difficulty) => Number(difficulty) || null,
      created: (created) => toDateString(created),
      modified: (modified) => toDateString(modified)
    }
  }

  validation() {
    return {
    }
  }

  routes() {
    return {
      save: '/courses/{course_id}/questions/{question_id}/answers',
    }
  }
}


