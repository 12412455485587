// Postgres saves all time in UTC by default. Unfortunately, when python
// converts the datetime to string, it doesn't add the 'Z' or '+00' for UTC,
// so javascript's Date() parsing fails, so we have to add it manually.
export function toDateString(dbDate) {
    let newDate = new Date(dbDate + 'Z').toLocaleString()
    try {
        if (isNaN(newDate.getTime())) {
            newDate = new Date(dbDate).toLocaleString()
        }
    } catch (TypeError) {
        newDate = new Date(dbDate).toLocaleString()
    }

    return newDate.toLocaleString()
}

export function toJsDate(dbDate) {
    return new Date(dbDate)
}

export function convertDateToEpoch(dbDate) {
    let newDate = new Date(dbDate)
    return newDate.valueOf()
}
