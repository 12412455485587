import {Bookmark} from '@/models/question/Bookmark'
import axios from "axios";

export const bookmark = {
    namespaced: true,
    state: {
        isBookmarked: false,
    },
    getters: {},
    mutations: {
        setIsBookmarked(state, isBookmarked) {
            state.isBookmarked = isBookmarked;
        }
    },
    actions: {
        bookmarkQuestion(context, payload) {
            let bookmark = new Bookmark(payload);
            bookmark.save().then().catch((error) => {
                context.commit('error/add', {
                    error: error,
                    message: 'Failed to save bookmark.'
                }, {root: true})
            })
        },
        unbookmarkQuestion(context, payload) {
            let bookmark = new Bookmark(payload);
            bookmark.delete().then().catch((error) => {
                context.commit('error/add', {
                    error: error,
                    message: 'Failed to save bookmark.'
                }, {root: true})
            })
        },


        getIsBookmarked(context, question_id) {
            axios.get("/questions/" + question_id + "/bookmarks")
                .then((response) => {
                    context.commit('setIsBookmarked', response.data)
                }).catch((error) => {
                context.commit('error/add', {
                    error: error,
                    message: 'Failed to get question is bookmarked.'
                }, {root: true})
            })
        }
    }
}
