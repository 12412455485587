<template>
	<div class='inline'>
		<img class='inline' src='@/assets/spinners/spinner-32px.svg' />
	</div>
</template>

<script>
export default {
	name: 'Loading'
}
</script>
