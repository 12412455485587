import {ModelBase, CollectionBase} from '@/models/ModelBases'
import {integer, required, string} from 'vue-mc/validation'

import {toDateString} from '@/models/Helpers'


export class Interaction extends ModelBase {
  defaults() {
    return {
      id: null,
      user_id: null,
      course_id: null,
      question_id: null,
      action: '',
      created: '',
      modified: ''
    }
  }

  mutations() {
    return {
      created: (created) => toDateString(created),
      modified: (modified) => toDateString(modified)
    }
  }

  validation() {
    return {
      user_id: integer.and(required),
      course_id: integer.and(required),
      question_id: integer.and(required),
      action: string.and(required)
    }
  }

  routes() {
    return {
      save: '/courses/{course_id}/questions/{question_id}/interactions',
      get: '/interactions/start/{startTime}/end/{endTime}'
    }
  }
}



export class InteractionsInPeriod extends CollectionBase {
  defaults() {
    return {
      user_id: null,
      start_time: null,
      end_time: null
    }
  }

  model() {
    return Interaction
  }

  routes() {
    return {
      fetch: '/interactions?startTime={start_time}&endTime={end_time}',
    }
  }
}

