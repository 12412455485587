import {Interaction} from '@/models/question/Interaction'
import {Answer} from '@/models/question/Answer'

export const interaction = {
  namespaced: true,
  state: {
    actions: {
      VIEW: 'View',
      SKIP: 'Skip',
      HINT: 'Hint',
      REVEAL: 'Reveal',
      ANSWER: 'Answer',
      CORRECT: 'Correct',
      INCORRECT: 'Incorrect'
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    recordAnswer(context, payload) {
      payload.action = context.state.actions.ANSWER
      context.dispatch('record', payload)
      let answer = new Answer(payload)
      answer.save().then().catch((error) => {
        context.commit('error/add', {error: error,
          message: 'Failed to save answer.'}, {root: true})
      })
    },
    recordCorrect(context, payload) {
      payload.action = context.state.actions.CORRECT
      context.dispatch('record', payload)
    },
    recordHint(context, payload) {
      payload.action = context.state.actions.HINT
      context.dispatch('record', payload)
    },
    recordIncorrect(context, payload) {
      payload.action = context.state.actions.INCORRECT
      context.dispatch('record', payload)
    },
    recordReveal(context, payload) {
      payload.action = context.state.actions.REVEAL
      context.dispatch('record', payload)
    },
    recordSkip(context, payload) {
      payload.action = context.state.actions.SKIP
      context.dispatch('record', payload)
    },
    recordView(context, payload) {
      payload.action = context.state.actions.VIEW
      context.dispatch('record', payload)
    },
    record(context, payload) {
      let interaction = new Interaction(payload)
      interaction.save().then().catch((error) => {
        context.commit('error/add', {error: error,
          message: 'Failed to save interaction.'}, {root: true})
      })
    }
  }
}
